export const environment = {
  npwp:"026709899605000",
  namaPerusahaan:"Portindo Marwa Jaya",
  namaProvider:"Mitra Inovasi Unggul",
  uiBarangKirimanDraftApiUrl : "https://prodserver-portindo-ui-barangkiriman-draft-api-786567254893.asia-southeast2.run.app",
  uiBarangKirimanBarangApiUrl : "https://prodserver-portindo-ui-barangkiriman-barang-api-786567254893.asia-southeast2.run.app",
  uiBarangKirimanBillingApiUrl : "https://prodserver-ui-barangkiriman-billing-api-786567254893.asia-southeast2.run.app",
  uiBarangKirimanReportApiUrl : "https://prodserver-ui-barangkiriman-report-api-786567254893.asia-southeast2.run.app",
  uiBarangKirimanUserApiUrl : "https://prodserver-ui-user-api-786567254893.asia-southeast2.run.app",

  firebaseConfig : {
    apiKey: "AIzaSyCS52GGOJBwNQIEFmjKsR2oHwqWpQd61hw",
    authDomain: "pibjk-v1-prod.firebaseapp.com",
    projectId: "pibjk-v1-prod",
    storageBucket: "pibjk-v1-prod.appspot.com",
    messagingSenderId: "786567254893",
    appId: "1:786567254893:web:8a0b22cab5319df766c064",
    measurementId: "G-FK53MBM6WZ"
  }
};
